<template>
    <div class="PriceEdit" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card>
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="editForm" label-width="80px" :model="form" @submit.native.prevent :rules="rules" size="small">
                <el-form-item label="机构类型">
                    <el-input readonly v-model="formExtend.deptTypeName" />
                </el-form-item>
                <el-form-item label="机构名称" prop="deptCode">
                    <el-input readonly v-model="formExtend.deptName" />
                    <el-input readonly v-model="form.deptCode" style="display: none" />
                </el-form-item>
                <el-form-item label="商品" prop="goodsCode">
                    <el-input readonly v-model="formExtend.name" />
                    <el-input readonly v-model="form.goodsCode" style="display: none" />
                </el-form-item>
                <el-form-item
                    label="出厂价"
                    v-if="showFactoryPriceFlag && hasPrivilege('biz.price.factory.query')"
                    prop="showFactoryPrice"
                    :rules="rules.showFactoryPrice"
                >
                    <ef-price-input v-model="form.showFactoryPrice" size="small" :precision="3" />
                </el-form-item>
                <el-form-item
                    label="批发价"
                    prop="showWholeSalePrice"
                    :rules="rules.showPrices"
                    v-if="hasPrivilege('biz.price.wholeSale.query')"
                >
                    <ef-price-input v-model="form.showWholeSalePrice" size="small" :precision="2" />
                </el-form-item>
                <el-form-item
                    label="零售价"
                    prop="showRetailPrice"
                    :rules="rules.showPrices"
                    v-if="hasPrivilege('biz.price.retail.query')"
                >
                    <ef-price-input v-model="form.showRetailPrice" size="small" :precision="2" />
                </el-form-item>
                <el-form-item
                    label="计划成本"
                    v-if="showPlanCostPricesFlag()"
                    prop="showPlanCostPrice"
                    :rules="rules.showPlanCostPrice"
                >
                    <ef-price-input v-model="form.showPlanCostPrice" size="small" :precision="4" />
                    <span style="color: red">计划成本每月只可以变更一次</span>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSave" size="small">保存</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import Utils from 'js/Util';
import MoneyUtils from 'js/MoneyUtils';
import { deptCodeValidateRule, goodsCodeValidateRule, pricesValidateRule } from 'js/validate/ValidateCommonRule';
import EfPriceInput from 'components/EfPriceInput';

export default {
    name: 'PriceEdit',
    components: { EfPriceInput },
    props: {
        code: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            formExtend: {
                deptType: null,
                deptTypeName: '',
                createTime: '',
                creator: '',
                deptName: '',
                name: '',
            },
            form: {
                code: '',
                deptCode: '',
                goodsCode: '',
                factoryPrice: null,
                wholeSalePrice: null,
                retailPrice: null,
                planCostPrice: null,

                showFactoryPrice: null,
                showWholeSalePrice: null,
                showRetailPrice: null,
                showPlanCostPrice: null,
            },
            rules: {
                deptCode: deptCodeValidateRule(),
                goodsCode: goodsCodeValidateRule(),
                showFactoryPrice: [
                    { type: 'number', required: true, min: 0.001, message: '必须大于0', trigger: 'blur' },
                    pricesValidateRule(3),
                ],
                showPrices: [
                    { type: 'number', required: true, min: 0.01, message: '必须大于0', trigger: 'blur' },
                    pricesValidateRule(2),
                ],
                showPlanCostPrice: [
                    { type: 'number', required: true, min: 0.0001, message: '必须大于0', trigger: 'blur' },
                    pricesValidateRule(4),
                ],
            },
        };
    },
    mounted() {
        (async () => {
            const rst = await this.$efApi.goodsPriceChangeApi.detail(this.code);
            Utils.copyProperties(rst, this.form);
            Utils.copyProperties(rst, this.formExtend);
            this.form.showFactoryPrice = MoneyUtils.moneyToThree(this.form.factoryPrice);
            this.form.showWholeSalePrice = MoneyUtils.moneyToYuan(this.form.wholeSalePrice);
            this.form.showRetailPrice = MoneyUtils.moneyToYuan(this.form.retailPrice);
            this.form.showPlanCostPrice = MoneyUtils.moneyToFour(this.form.planCostPrice);
        })();
    },
    computed: {
        showFactoryPriceFlag() {
            return this.formExtend.deptType === 2;
        },
    },
    methods: {
        handleSave() {
            this.$refs.editForm.validate(async (valid) => {
                if (!valid) {
                    return false;
                }
                this.form.factoryPrice = MoneyUtils.moneyToDb(this.form.showFactoryPrice);
                this.form.wholeSalePrice = MoneyUtils.moneyToDb(this.form.showWholeSalePrice);
                this.form.retailPrice = MoneyUtils.moneyToDb(this.form.showRetailPrice);
                this.form.planCostPrice = MoneyUtils.moneyToDb(this.form.showPlanCostPrice);
                await this.$efApi.goodsPriceChangeApi.update(this.form.code, this.form);
                this.goBackAndReload();
            });
        },
        showPlanCostPricesFlag() {
            return this.hasPrivilege('biz.price.plan.edit');
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
